//服务收费单申请
<template>
  <Page ref="page" v-loading="loading" :showReturn="!isCreate">
    <div>
      <el-form ref="form" label-width="140px" :model="params" :rules="rules">
        <div class="cm-flex-line">
          <el-form-item label="订单日期：" prop="orderTime">
            <el-date-picker
              style="width: 100%"
              v-model="params.orderTime"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="{
                disabledDate(time) {
                  return time.getTime() > Date.now()
                }
              }"
            />
          </el-form-item>
          <el-form-item label="关联工单号：" prop="workOrderCode">
            <SelectPagination
              style="width: 100%"
              :default-value="params.workOrderCode"
              :get-list-methods="listWorkOrderNo"
              :clearable="true"
              search-key="workOrderCode"
              @selectedChange="changeWorkOrderCode"
            />
          </el-form-item>

          <el-form-item label="销售类型：" prop="sellType">
            <el-select style="width: 100%" v-model="params.sellType">
              <el-option
                :label="a.label"
                :value="a.value"
                :key="a.value"
                v-for="a in $constant.sellType"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="cm-flex-line">
          <el-form-item label="业务员：" prop="salesmanCode">
            <SelectProposer
              :code.sync="params.salesmanCode"
              :name.sync="params.salesmanName"
              @change="proposerChange"
            />
          </el-form-item>

          <el-form-item label="销售部门：" prop="salesDepartmentCode">
            <SelectDepartment
              ref="SelectDepartment"
              :code.sync="params.salesDepartmentCode"
              :name.sync="params.salesDepartmentName"
              disabled
            />
          </el-form-item>
          <el-form-item label="所属区域：" prop="area">
            <el-cascader
              v-model="params.area"
              :props="casProps"
              :options="casOptions"
              style="width: 100%"
            ></el-cascader>
          </el-form-item>
        </div>
        <div class="cm-flex-line">
          <!-- <el-form-item label="业务类型：" prop="businessType">
            <el-select
              disabled
              style="width: 100%"
              v-model="params.businessType"
            >
              <el-option
                :label="a.label"
                :value="a.value"
                :key="a.value"
                v-for="a in $constant.businessType"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="上门安装：" prop="installType">
            <el-select style="width: 100%" v-model="params.installType">
              <el-option label="否" :value="0"></el-option>
              <el-option label="是" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="保修方式：" prop="maintenanceType">
            <el-select style="width: 100%" v-model="params.maintenanceType">
              <el-option
                :label="a.label"
                :value="a.value"
                :key="a.value"
                v-for="a in $constant.maintenanceType"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="cm-flex-line">
          <el-form-item label="客户名称：" prop="clientCode">
            <SelectCustomer
              :code.sync="params.clientCode"
              :name.sync="params.clientName"
            />
          </el-form-item>
          <el-form-item label="付款方式：" prop="payWay">
            <el-select
              style="width: 100%"
              v-model="params.payWay"
              @change="changePayment"
            >
              <el-option
                :label="a.label"
                :value="a.value"
                :key="a.value"
                v-for="a in $constant.payWay"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="要求发货日期：" prop="requireDeliverTime">
            <el-date-picker
              style="width: 100%"
              v-model="params.requireDeliverTime"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="{
                disabledDate(time) {
                  return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
                }
              }"
            />
          </el-form-item>
        </div>
        <div class="cm-flex-line">
          <el-form-item label="联系人：" prop="linkman">
            <el-input
              v-model="params.linkman"
              placeholder="请输入联系人"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人手机：" prop="linkmanPhone">
            <el-input
              v-model="params.linkmanPhone"
              placeholder="请输入联系人手机"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人地址：" prop="linkmanAddress">
            <el-input
              v-model="params.linkmanAddress"
              placeholder="请输入联系人地址"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item label="备注：" prop="remark">
          <el-input
            v-model="params.remark"
            placeholder="请输入备注"
            type="textarea"
            maxlength="255"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="fileList" label="附件：">
          <UploadFile
            ref="UploadFile"
            v-model="params.fileList"
            :showAccept="false"
            :limit="3"
            multiple
            :preAppendixAddr="params && params.attachmentUrl"
          />
        </el-form-item>
        <el-divider content-position="left">费用清单</el-divider>
        <div style="display: flex; justify-content: end; margin-bottom: 10px">
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="addEvent()"
          >
            添加
          </el-button>
        </div>
        <el-table :data="params.detailUpdateInputs">
          <el-table-column header-align="center" label="料号">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.code'"
                :rules="rules.code"
              >
                <SelectGoodsCode
                  :code.sync="params.detailUpdateInputs[$index].code"
                  :name.sync="params.detailUpdateInputs[$index].name"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="名称">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.name'"
                :rules="rules.name"
              >
                <SelectGoodsName
                  :code.sync="params.detailUpdateInputs[$index].code"
                  :name.sync="params.detailUpdateInputs[$index].name"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="单价">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.price'"
                :rules="rules.price"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].price"
                  placeholder="请输入单价"
                  @input="
                    val =>
                      (params.detailUpdateInputs[$index].price =
                        $format.fmtAmt(val))
                  "
                  @blur="computeTotalAmount($index)"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="数量">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.num'"
                :rules="rules.num"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].num"
                  placeholder="请输入数量"
                  @input="
                    val =>
                      (params.detailUpdateInputs[$index].num =
                        $format.fmtNumber(val))
                  "
                  @blur="computeTotalAmount($index)"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="小计费用"
          >
            <template slot-scope="{ row, $index }">
              <div style="margin-bottom: 22px">
                {{ row.totalAmount | dividedInto }}
              </div>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="仓库">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.warehouseCode'"
                :rules="rules.warehouseCode"
              >
                <!-- 解决冲突 -->
                <SelectWarehouse
                  :code.sync="params.detailUpdateInputs[$index].warehouseCode"
                  :name.sync="params.detailUpdateInputs[$index].warehouseName"
                  @change="changeWarehouse"
                  :inventoryCode="params.detailUpdateInputs[$index].code"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="货位">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.storageCode'"
                :rules="rules.storageCode"
              >
                <SelectGoodsLocation
                  :ref="'SelectGoodsLocation' + $index"
                  :code.sync="params.detailUpdateInputs[$index].storageCode"
                  :name.sync="params.detailUpdateInputs[$index].storageName"
                  :maxNum.sync="params.detailUpdateInputs[$index].inventoryNum"
                  :params="{
                    inventoryCode: params.detailUpdateInputs[$index].code,
                    warehouseCode:
                      params.detailUpdateInputs[$index].warehouseCode
                  }"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="存货数量">
            <template slot-scope="{ row, $index }">
              <el-form-item label="" label-width="0">
                <el-input
                  v-model="params.detailUpdateInputs[$index].inventoryNum"
                  placeholder=""
                  disabled
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="备注">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.remark'"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].remark"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label=" ">
            <template slot-scope="{ row, $index }">
              <el-form-item label="" label-width="0">
                <el-button type="text" @click="deleteEvent($index)">
                  删除
                </el-button>
                <el-button type="text" @click="copyEvent($index)">
                  复制
                </el-button>
              </el-form-item>
            </template>
          </el-table-column>
          <template slot="empty">
            <div>暂无数据，请添加</div>
          </template>
        </el-table>
        <div class="cm-flex-line" style="margin-top: 20px">
          <el-form-item label="费用合计：" prop="priceTotal">
            <el-input
              v-model="params.priceTotal"
              placeholder="请输入总费用（元）"
              @input="val => (params.priceTotal = $format.fmtAmt(val))"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="有无特价："
            prop="specialHas"
            style="flex: inherit; width: 200px"
          >
            <el-switch
              v-model="params.specialHas"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>
          <el-form-item label="" label-width="30px" prop="specialOrderCode">
            <el-input
              v-model="params.specialOrderCode"
              placeholder="特价单号"
              v-show="params.specialHas"
            />
          </el-form-item>
          <el-form-item label="" label-width="30px" prop="specialAmount">
            <el-input
              v-model="params.specialAmount"
              placeholder="特价价格"
              v-show="params.specialHas"
            />
          </el-form-item>
        </div>
      </el-form>
    </div>
    <FlowableStep
      orderType="4"
      :variables="variables"
      @initNode="setNodes"
      @refresh="setAuditors"
    ></FlowableStep>
    <template slot="bottom">
      <el-button
        type="primary"
        @click="submitEvent(1)"
        style="margin-right: 10px"
      >
        提交申请
      </el-button>
      <el-button type="info" @click="submitEvent(2)">保存草稿</el-button>
    </template>
  </Page>
</template>

<script>
import FlowableStep from '@/components/FlowableStep'
import SelectPagination from '@/components/SelectPagination'
import applyMixins from '../../mixins/applyMixins'
export default {
  mixins: [applyMixins],
  components: { FlowableStep, SelectPagination },
  data() {
    let self = this
    return {
      listWorkOrderNo: this.$api.workOrder.listWorkOrderNo,
      rules: {
        orderTime: [{ required: true, message: '请选择订单日期' }],
        sellType: [{ required: true, message: '请选择销售类型' }],
        businessType: [{ required: true, message: '请选择业务类型' }],
        maintenanceType: [{ required: true, message: '请选择保修方式' }],
        salesDepartmentCode: [{ required: true, message: '请选择销售部门' }],
        area: [{ required: true, message: '请选择所属区域' }],
        salesmanCode: [{ required: true, message: '请选择业务员' }],
        clientCode: [{ required: true, message: '请选择客户名称' }],
        payWay: [{ required: true, message: '请选择付款方式' }],
        requireDeliverTime: [{ required: true, message: '请选择发货日期' }],
        linkman: [{ required: true, message: '请输入联系人' }],
        linkmanPhone: [
          { required: true, message: '请输入联系人手机' },
          { min: 11, max: 11, message: '请输入正确的手机号' }
        ],
        linkmanAddress: [{ required: true, message: '请输入联系人地址' }],
        specialAmount: [
          {
            validator: (rule, value, callback) => {
              if (this.params.specialHas == 1 && !value) {
                callback('请输入特价后价格')
              } else {
                callback()
              }
            }
          }
        ],
        num: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value || Number(value) === 0) {
                callback('请输入非0整数')
              } else {
                callback()
              }
            }
          }
        ],
        price: [{ required: true, message: '请输入' }],
        code: [{ required: true, message: '请选择' }],
        name: [{ required: true, message: '请选择' }],
        warehouseCode: [{ required: true, message: '请选择' }],
        storageCode: [
          {
            required: false,
            validator: (rule, value, callback) => {
              try {
                let index = rule.field?.split('.')[1]
                const $elem = this.$refs['SelectGoodsLocation' + index]
                const list = $elem?.list || []
                if (list.length > 0 && !value) {
                  callback('请选择货位')
                } else {
                  callback()
                }
              } catch (e) {
                console.log(e)
              }
            }
          }
        ]
      },
      params: {
        businessType: 1,
        sellType: 2,
        specialHas: 0,
        detailUpdateInputs: [],
        auditors: {}
      },
      nodes: [],
      variables: {
        PAY_WAY: '', // 条件参数值
        WAREHOUSE_OUT: '' // 条件参数值
      },
      casOptions: [], //省市区默认
      casProps: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const { level } = node
          let list = []
          if (level === 0) {
            list = await self.$api.common.getProvince({
              page: 1,
              limit: 1000
            })
            list = (list || []).map(a => {
              return { label: a.province, value: a.provinceId }
            })
          } else if (level === 1) {
            list = await self.$api.common.getCity({
              province: node.data.value
            })
            list = (list || []).map(a => {
              return { label: a.city, value: a.cityId }
            })
          } else if (level === 2) {
            list = await self.$api.common.getArea({
              city: node.data.value
            })
            list = (list || []).map(a => {
              return { label: a.area, value: a.areaId, leaf: true }
            })
          }

          resolve(list)
        }
      }
    }
  },
  watch: {
    //监听计算费用合计
    'params.detailUpdateInputs': {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        if (newV.length === 0) {
          this.$set(this.params, 'priceTotal', '')
        } else {
          let sum = 0
          newV.forEach(a => {
            if (a.totalAmount > 0) {
              sum += a.totalAmount
            }
          })
          this.$set(this.params, 'priceTotal', sum)
        }
      }
    }
  },
  computed: {
    isCreate() {
      return this.$route.query.edit != 1
    }
  },
  async mounted() {
    this.loading = true
    this.init()
    this.loading = false
  },
  methods: {
    changePayment(val) {
      this.variables['PAY_WAY'] = val
      this.variables['WAREHOUSE_OUT'] =
        this.params.detailUpdateInputs
          ?.filter(item => item.warehouseCode)
          .map(item => item.warehouseCode) || []
    },
    changeWarehouse(data) {
      this.variables['PAY_WAY'] = this.params.clientCode
      this.variables['WAREHOUSE_OUT'] =
        this.params.detailUpdateInputs
          ?.filter(item => item.warehouseCode)
          .map(item => item.warehouseCode) || []
    },
    setNodes(nodes) {
      this.nodes = nodes
    },
    setAuditors(auditors) {
      this.params.auditors = auditors
    },
    async init() {
      try {
        const { orderCode, workOrderCode = '' } = this.$route.query
        if (workOrderCode) {
          let data = await this.$api.workOrder.getWorkOrder({
            workOrderCode: workOrderCode
          })
          let params = {
            workOrderCode: data.workOrderCode,
            linkman: data.linkedHuman,
            linkmanPhone: data.linkedHumanPhone,
            remark: data.description,
            auditors: {},
            detailUpdateInputs: [],
            businessType: 1,
            sellType: 2,
            specialHas: 0
          }
          this.params = params
        } else {
          const res = await this.$api.apply.sellOrderInfo({ orderCode })
          if (res) {
            let { sellOrderResult, detailResults } = res
            sellOrderResult.specialHas = sellOrderResult.specialAmount ? 1 : 0
            sellOrderResult.specialAmount = sellOrderResult.specialAmount
              ? sellOrderResult.specialAmount / 100
              : ''
            detailResults = detailResults.map(a => {
              return {
                ...a,
                price: a.price / 100,
                totalAmount: a.totalAmount / 100
              }
            })
            sellOrderResult.area = [
              sellOrderResult.provinceId,
              sellOrderResult.cityId,
              sellOrderResult.areaId
            ]
            let params = {
              ...sellOrderResult,
              detailUpdateInputs: detailResults,
              auditors: {}
            }
            this.params = params
            //省市区
            this.initArea(sellOrderResult)
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    changeWorkOrderCode(workOrderCode) {
      this.params.workOrderCode = workOrderCode
    },
    //编辑时回显省市区
    async initArea(sellOrderResult) {
      let list0 = await this.$api.common.getProvince({
        page: 1,
        limit: 1000
      })
      list0 = (list0 || []).map(a => {
        return { label: a.province, value: a.provinceId }
      })
      let list1 = await this.$api.common.getCity({
        province: sellOrderResult.provinceId
      })
      list1 = (list1 || []).map(a => {
        return { label: a.city, value: a.cityId }
      })
      let list2 = await this.$api.common.getArea({
        city: sellOrderResult.cityId
      })
      list2 = (list2 || []).map(a => {
        return { label: a.area, value: a.areaId, leaf: true }
      })
      list1 = list1.map(a => {
        if (a.value === sellOrderResult.cityId) a.children = list2
        return a
      })
      list0 = list0.map(a => {
        if (a.value === sellOrderResult.provinceId) a.children = list1
        return a
      })
      this.casOptions = list0
    },
    addEvent() {
      this.params.detailUpdateInputs.push({
        num: '',
        code: '',
        name: '',
        price: '',
        remark: '',
        totalAmount: 0,
        warehouseCode: '',
        warehouseName: '',
        storageCode: '',
        storageName: ''
      })
    },
    deleteEvent(index) {
      this.params.detailUpdateInputs = this.params.detailUpdateInputs.filter(
        (a, i) => i !== index
      )
    },
    copyEvent(index) {
      let detail = this.params.detailUpdateInputs.find((a, i) => i === index)
      this.params.detailUpdateInputs.push({ ...detail })
    },
    submitEvent(submit) {
      if (this.loading) return
      let params = this.$utils.deepClone(this.params)
      if (params.orderTime) {
        params.orderTime = params.orderTime + ' 00:00:00'
      }
      if (params.requireDeliverTime) {
        params.requireDeliverTime = params.requireDeliverTime + ' 23:59:59'
      }
      if (params.fileList?.length > 0) {
        params.attachmentUrl = params.fileList
          .map(a => {
            return `${a.url}?fileName=${a.name}`
          })
          .join(',')
        delete params.fileList
      }
      if (params.specialHas == 0) {
        delete params.specialAmount
        delete params.specialOrderCode
      } else {
        params.specialAmount = Number(params.specialAmount) * 100 //元转分
      }
      //省市区
      if (params.area?.length == 3) {
        params.provinceId = params.area[0]
        params.cityId = params.area[1]
        params.areaId = params.area[2]
      }
      delete params.area
      let detailUpdateInputs = params.detailUpdateInputs
      detailUpdateInputs = detailUpdateInputs.map(a => {
        let price = Number(a.price) * 100 //元转分
        let totalAmount = Number(a.totalAmount) * 100 //元转分
        return { ...a, price, totalAmount }
      })

      delete params.detailUpdateInputs
      let newParams = {
        submit: submit == 1,
        detailUpdateInput: detailUpdateInputs,
        sellOrderUpdateInput: params
      }
      if (submit != 1) {
        try {
          this.loading = true
          this.$api.apply.sellOrderSave(newParams).then(result => {
            this.$message.success('草稿保存成功')
          })
        } finally {
          this.loading = false
        }
      } else {
        if (this.nodes.length) {
          let flag = true
          for (let i = 0; i < this.nodes.length; i++) {
            if (
              this.nodes[i].assigneeType == 'SELF_SELECT' &&
              this.nodes[i].userType == 2 &&
              this.nodes[i].users.length == 0 &&
              !this.params?.auditors[this.nodes[i].id]?.length
            ) {
              flag = false
              return this.$message.warning('请选择审批人后提交')
            }
          }
          console.log('flag', flag)
          if (!flag) return
        }
        this.$refs['form'].validate(async valid => {
          if (valid) {
            try {
              if (this.params.detailUpdateInputs.length === 0) {
                this.$message.warning('请至少添加一条物品清单')
                return
              }
              this.loading = true
              clearInterval(this.timeSaveFlag)
              await this.$api.apply.sellOrderSave(newParams)
              //提交成功
              this.submitSuccess('/#/sellOrderApply')
            } finally {
              this.loading = false
            }
          }
        })
      }
    },
    //业务员改变
    proposerChange(a) {
      this.$nextTick(() => {
        this.$refs.SelectDepartment?.changeEvent(a.cdepCode)
      })
    },
    //计算小计费用
    computeTotalAmount(index) {
      let item = this.$utils.deepClone(this.params.detailUpdateInputs[index])
      const { num, price } = item
      let totalAmount = 0
      if (!num || !price) {
        totalAmount = 0
      } else {
        totalAmount = Number(num) * Number(price)
      }
      item = { ...item, totalAmount }
      this.$set(this.params.detailUpdateInputs, index, item)
    }
  }
}
</script>
