<template>
	<el-select
		v-model="activeValue"
		v-loadData="loadData"
		remote
		filterable
		:remote-method="searchMethod"
		class="more-select"
		:loading="loading"
		:multiple="multiple"
		:clearable="clearable"
		@change="selectChange"
	>
		<!-- 只有isShow定义了false，才进行隐藏，否则正常显示 -->
		<el-option
			v-for="(item, index) in list"
			v-show="item.isShow === false ? false : true"
			:key="item.value + `'${index}'`"
			:label="item.label"
			:value="item.value"
		/>
	</el-select>
</template>

<script>
import Vue from 'vue'
Vue.directive('loadData', {
	bind(el, binding) {
		// 获取element-ui定义好的scroll盒子
		const SELECTWRAP_DOM = el.querySelector(
			'.el-select-dropdown .el-select-dropdown__wrap'
		)
		SELECTWRAP_DOM.addEventListener('scroll', function () {
			/**
			 * scrollHeight 获取元素内容高度(只读)
			 * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
			 * clientHeight 读取元素的可见高度(只读)
			 * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
			 * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
			 */
			const condition =
				this.scrollHeight - this.scrollTop <= this.clientHeight
			//监听下拉框是否滚动到底部，滚动到底部就加载下一页数据
			if (condition) binding.value()
		})
	}
})
export default {
	props: {
		// 默认选中值
		defaultValue: {
			type: [Array, String, Number],
			default: ''
		},
		// 是否可以多选
		multiple: {
			type: Boolean,
			default: false
		},
		// 是否可以清空
		clearable: {
			type: Boolean,
			default: false
		},
		// 远程搜索时，输入框搜索值的键名
		searchKey: {
			type: String,
			default: 'searchKey'
		},
		// 获取下拉框值得方法名
		getListMethods: {
			type: Function,
			default: null
		},
		// 获取下拉框值时 默认参数
		getListParams: {
			type: Object,
			default: () => ({})
		},
		// 下拉框值的格式
		getListFormat: {
			type: Function,
			default: data => {
				return data.map(({ label, value }) => ({ label, value }))
			}
		}
	},
	data() {
		return {
			list: [],
			activeValue: '',
			searchText: '',
			pageNum: 1,
			pageSize: 10,
			loading: false,
			finished: false // 是否加载完所有数据
		}
	},
	watch: {
		defaultValue:{
			deep:true,
			handler(newV,oldV){
				this.activeValue=newV
			}
		}
	},
	created() {
		this.getData()
	},
	methods: {
		getData() {
			if (!this.getListMethods || this.finished) return
			this.loading = true
			const params = {
				...this.getListParams,
				[this.searchKey]: this.searchText,
				pageNum: this.pageNum,
				pageSize: this.pageSize
			}
			this.getListMethods(params)
				.then(data => {
					// 对数据进行格式化
					data = this.getListFormat(data)
					// 判断是否是最后一页了
					if (data.length < this.pageSize) this.finished = true

					this.list = this.list.concat(data)
				})
				.finally(() => {
					this.loading = false
				})
		},
		searchMethod(searchText) {
			if (searchText == this.searchText) return
			// 防抖处理
			let timeout = null
			clearTimeout(timeout)
			timeout = setTimeout(() => {
				this.list = []
				this.pageNum = 1
				this.finished = false
				this.searchText = searchText
				this.getData()
			}, 500)
		},
		loadData() {
			// 防抖处理
			let timeout = null
			clearTimeout(timeout)
			timeout = setTimeout(() => {
				if (this.finished) return
				this.pageNum += 1
				this.getData()
			}, 500)
		},
		reset() {
			this.activeValue = ''
		},
		selectChange(value) {
			this.$emit('selectedChange', value)
		}
	}
}
</script>
